const Bmi = ({ bmi }) => {
    return (
        <div>
            BMI{' '}
            <span>
                <b>{bmi}</b> kg/m²
            </span>
        </div>
    )
}

export default Bmi
