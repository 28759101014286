const Rbs = ({ rbs }) => {
    return (
        <div>
            RBS{' '}
            <span>
                <b>{rbs}</b> mmol/L
            </span>
        </div>
    )
}

export default Rbs
