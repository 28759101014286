const Height = ({ height }) => {
    return (
        <div>
            Height
            <span>
                <b>{height.feet}</b> feet <b>{height.inch}</b> inch
            </span>
        </div>
    )
}

export default Height
